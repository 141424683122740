import { METHOD, frontmanZendeskApi } from '@/utils/api'

import { ZendeskRequestParams } from './types'

export type GetSectionsRequest = ZendeskRequestParams

type Pagination = {
  page: number
  per_page: number
  count: number
  page_count: number
  next_page: string | null
  previous_page: string | null
  sort_by: string
  sort_order: string
}

// [DOC] https://developer.zendesk.com/api-reference/help_center/help-center-api/sections/
export type SectionItem = {
  category_id?: number
  created_at?: string
  description?: string
  html_url?: string
  id: number
  locale: string
  name: string
  outdated?: boolean
  parent_section_id?: number
  position?: number
  sorting?: string // DOC에 없는 필드
  source_locale?: string
  theme_template?: string
  updated_at?: string
  url?: string
}

export interface GetSectionsResponse extends Pagination {
  sections: SectionItem[]
}

export const getSections = (categoryId: string, query: GetSectionsRequest) => {
  return frontmanZendeskApi<GetSectionsResponse>({
    path: `/api/zendesk/help_center/ko/categories/${categoryId}/sections`,
    method: METHOD.GET,
    query,
    isProxy: true,
  })
}
