import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useMutation } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { find } from 'lodash-es'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { getQnaList } from '@/apis/frontman/ticketing/getQnaList'
import { REQUESTS_STATUS } from '@/apis/frontman/ticketing/types'
import { Container as InputContainer } from '@/components/Input'
import IcoSearch from '@/components/Input/images/ico_search.svg?url'
import Portal from '@/components/Portal'
import FrameLayout from '@/components/layout/FrameLayout'
import Header from '@/components/layout/Header'
import { LOG_PAGE_ID } from '@/constants/log'
import { ROUTER } from '@/constants/router'
import { TICKET_FIELD_ID } from '@/constants/zendesk'
import useQueryStringController from '@/hooks/useQueryStringController'
import { useRecommendKeywords } from '@/hooks/useRecommendKeywords'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'
import { fontSize, touchGuide } from '@/styles/mixin'

import KeywordButtons from '../shared/components/KeywordButtons'
import BestFaqSection from './BestFaqSection'
import CategorySection from './CategorySection'
import EtcSection from './EtcSection'
import SearchContainer from './Search/SearchContainer'
import Section from './shared/components/Section'

const FROM_DAYS_PARAM = 31

function HomePage() {
  const router = useRouter()
  const inputRef = useRef<HTMLInputElement>(null)
  const { updateQuery } = useQueryStringController()
  const [isNewReplies, setIsNewReplies] = useState(false)
  const { data: recommendkeywords, status: recommendkeywordsStatus } = useRecommendKeywords()

  const isSearchOpen = useMemo(() => router.query.keyword !== undefined, [router.query])

  const handleClickQnaList = useCallback(() => {
    sendEvent(LOG_PAGE_ID.MAIN, 'tap.mycs')

    router.push(ROUTER.QNA.LIST)
  }, [router])

  const { mutate: checkNewReplies } = useMutation({
    mutationFn: () =>
      getQnaList({
        page: 1,
        per_page: 10,
        status: REQUESTS_STATUS.SOLVED,
        sort_by: 'updated_at',
        sort_order: 'desc',
      }),
    onSuccess: ({ count, requests }) => {
      if (count > 0) {
        const unread = requests.filter(({ custom_fields, updated_at }) => {
          const readMarkerField = find(
            custom_fields,
            ({ id }) => id === TICKET_FIELD_ID.READ_MARKER
          )
          const diff = dayjs().diff(dayjs(updated_at), 'day')
          return readMarkerField && !readMarkerField.value && diff <= FROM_DAYS_PARAM
        })
        setIsNewReplies(unread.length > 0)
      }
    },
  })

  const handleClickSearchTrigger = useCallback(() => {
    sendEvent(LOG_PAGE_ID.MAIN, 'tap.search')
    updateQuery({ keyword: '' })
    inputRef.current?.focus()
  }, [updateQuery])

  const handleClickRecommendKeyword = useCallback(
    (keyword: string | boolean) => {
      updateQuery({ keyword })

      sendEvent(LOG_PAGE_ID.MAIN, 'tap.recommendsearch', {
        recommendsearch_name: keyword.toString(),
      })
    },
    [updateQuery]
  )

  useEffect(() => {
    checkNewReplies()
  }, [checkNewReplies])

  useEffect(() => {
    if (isSearchOpen) {
      sendEvent(LOG_PAGE_ID.SEARCH)
    } else {
      sendEvent(LOG_PAGE_ID.MAIN)
    }
  }, [isSearchOpen])

  return (
    <FrameLayout>
      <Header
        pageId={LOG_PAGE_ID.MAIN}
        extra={
          <QnaHistoryButton
            data-cy={'qna-history-button'}
            isNewReplies={isNewReplies}
            onClick={handleClickQnaList}
          >
            내 문의
          </QnaHistoryButton>
        }
      />
      <Main>
        <Section>
          <Title>
            고객님,
            <br />
            무엇을 도와드릴까요?
          </Title>
          <SearchBlock>
            <SearchTrigger onClick={handleClickSearchTrigger}>
              <InputContainerCopy type="search">
                <PlaceHolder>단어로 검색하세요. 예&#41; 운전점수</PlaceHolder>
                <SearchIcon />
              </InputContainerCopy>
            </SearchTrigger>
            <KeywordButtons
              isLoading={!['error', 'success'].includes(recommendkeywordsStatus)}
              keywords={recommendkeywords}
              onClick={handleClickRecommendKeyword}
            />
          </SearchBlock>
        </Section>
        <BestFaqSection />
        <CategorySection />
        <EtcSection />
      </Main>
      <Portal id="__search">
        <SearchContainer
          ref={inputRef}
          open={isSearchOpen}
        />
      </Portal>
    </FrameLayout>
  )
}

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${COLOR.gray.color.gray[100]};

  & > section:first-of-type {
    padding-top: 0;
  }
`

const QnaHistoryButton = styled.button<{ isNewReplies: boolean }>`
  position: relative;
  color: ${COLOR.gray.color.gray[900]};

  ${({ isNewReplies }) =>
    isNewReplies &&
    css`
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -3px;
        right: -6px;
        width: 6px;
        height: 6px;
        border-radius: 100%;
        background: ${COLOR.semantic.color.red[500]};
      }
    `}

  ${touchGuide({
    borderRadius: '4px',
    top: -4,
    bottom: -4,
    left: -4,
    right: -4,
  })}
`

const Title = styled.h2`
  ${({ theme }) => fontSize(theme, 20)}
  font-weight: var(--font-weight-bold);
  color: ${COLOR.gray.color.gray[900]};
`

const SearchBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;
`

const SearchTrigger = styled.button`
  color: ${COLOR.gray.color.gray[400]};
  text-align: left;
`

const InputContainerCopy = styled(InputContainer)`
  position: relative;
  flex-wrap: nowrap;
  height: 48px;
  ${touchGuide({ borderRadius: '22px' })}
`

const PlaceHolder = styled.span`
  display: block;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
`

const SearchIcon = styled.i`
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(${IcoSearch}) no-repeat center center;
`

export default HomePage
